import { render, staticRenderFns } from "./editfloor.vue?vue&type=template&id=240f4208&scoped=true&"
import script from "./editfloor.vue?vue&type=script&lang=js&"
export * from "./editfloor.vue?vue&type=script&lang=js&"
import style0 from "./editfloor.vue?vue&type=style&index=0&id=240f4208&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "240f4208",
  null
  
)

export default component.exports