<template>
    <div id="editFloorPage" class="baseTemplate">
        <!-- begin:: mobile menu -->
        <app-mobilemenu></app-mobilemenu>
        <!-- end:: mobile menu -->

        <div class="kt-grid kt-grid--hor kt-grid--root">
            <div
                class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page"
            >
                <!-- begin:: home menu -->
                <app-siteconfigurationmenu
                    activeItem="editFloor"
                ></app-siteconfigurationmenu>
                <!-- end:: home menu -->

                <div
                    class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper"
                >
                    <!-- begin:: header -->
                    <app-header></app-header>
                    <!-- end:: header -->

                    <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                        <!-- begin:: Content Head -->
                        <div class="kt-subheader kt-grid__item">
                            <app-sitetitle
                                subHeaderDescKey="b&f_editFloorSubTitle"
                            ></app-sitetitle>
                        </div>
                        <!-- end:: Content Head -->

                        <!-- begin:: Content -->
                        <div class="kt-content kt-grid__item kt-grid__item--fluid">
                            <!-- begin:: Content -->

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="kt-portlet">
                                        <div class="kt-portlet__head">
                                            <div class="kt-portlet__head-label">
                                                <span class="kt-portlet__head-icon">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                        width="24px"
                                                        height="24px"
                                                        viewBox="0 0 24 24"
                                                        version="1.1"
                                                        class="kt-svg-icon kt-svg-icon--success"
                                                    >
                                                        <g
                                                            stroke="none"
                                                            stroke-width="1"
                                                            fill="none"
                                                            fill-rule="evenodd"
                                                        >
                                                            <polygon
                                                                id="Bound"
                                                                points="0 0 24 0 24 24 0 24"
                                                            ></polygon>
                                                            <path
                                                                d="M12.9336061,16.072447 L19.36,10.9564761 L19.5181585,10.8312381 C20.1676248,10.3169571 20.2772143,9.3735535 19.7629333,8.72408713 C19.6917232,8.63415859 19.6104327,8.55269514 19.5206557,8.48129411 L12.9336854,3.24257445 C12.3871201,2.80788259 11.6128799,2.80788259 11.0663146,3.24257445 L4.47482784,8.48488609 C3.82645598,9.00054628 3.71887192,9.94418071 4.23453211,10.5925526 C4.30500305,10.6811601 4.38527899,10.7615046 4.47382636,10.8320511 L4.63,10.9564761 L11.0659024,16.0730648 C11.6126744,16.5077525 12.3871218,16.5074963 12.9336061,16.072447 Z"
                                                                id="Shape"
                                                                fill="#000000"
                                                                fill-rule="nonzero"
                                                            ></path>
                                                            <path
                                                                d="M11.0563554,18.6706981 L5.33593024,14.122919 C4.94553994,13.8125559 4.37746707,13.8774308 4.06710397,14.2678211 C4.06471678,14.2708238 4.06234874,14.2738418 4.06,14.2768747 L4.06,14.2768747 C3.75257288,14.6738539 3.82516916,15.244888 4.22214834,15.5523151 C4.22358765,15.5534297 4.2250303,15.55454 4.22647627,15.555646 L11.0872776,20.8031356 C11.6250734,21.2144692 12.371757,21.2145375 12.909628,20.8033023 L19.7677785,15.559828 C20.1693192,15.2528257 20.2459576,14.6784381 19.9389553,14.2768974 C19.9376429,14.2751809 19.9363245,14.2734691 19.935,14.2717619 L19.935,14.2717619 C19.6266937,13.8743807 19.0546209,13.8021712 18.6572397,14.1104775 C18.654352,14.112718 18.6514778,14.1149757 18.6486172,14.1172508 L12.9235044,18.6705218 C12.377022,19.1051477 11.6029199,19.1052208 11.0563554,18.6706981 Z"
                                                                id="Path"
                                                                fill="#000000"
                                                                opacity="0.3"
                                                            ></path>
                                                        </g>
                                                    </svg>
                                                </span>
                                                <h3 class="kt-portlet__head-title">
                                                    {{ $t("b&f_editFloor") }}
                                                </h3>
                                            </div>
                                        </div>

                                        <!--begin::Form-->
                                        <form
                                            id="editFloorForm"
                                            class="kt-form"
                                            novalidate="novalidate"
                                        >
                                            <div class="kt-portlet__body">
                                                <div class="kt-section">
                                                    <h3 class="kt-section__title">
                                                        1. {{ $t("b&f_floorInfo") }}
                                                    </h3>
                                                    <div class="form-group validated row">
                                                        <div class="col-lg-6">
                                                            <label for="editFloorForm_nameInput"
                                                            >{{ $t("b&f_floorName") }} *</label
                                                            >
                                                            <div class="input-group">
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text"
                                                                    ><i class="la la-pencil"></i
                                                                    ></span>
                                                                </div>
                                                                <input
                                                                    v-model="name"
                                                                    @input="$v.name.$touch()"
                                                                    @keyup="onChangeForm"
                                                                    type="text"
                                                                    class="form-control"
                                                                    id="editFloorForm_nameInput"
                                                                    :placeholder="$t('b&f_floorNameInputInfo')"
                                                                />
                                                            </div>
                                                            <div
                                                                v-if="!$v.name.required"
                                                                class="invalid-feedback"
                                                            >
                                                                {{ $t("error_fieldIsRequired") }}
                                                            </div>
                                                            <span class="form-text text-muted">{{
                                                                $t("b&f_floorNameDetailInfo")
                                                            }}</span>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <label class=""
                                                            >{{ $t("b&f_floorNumber") }} *</label
                                                            >
                                                            <select
                                                                id="editFloorFloorNumberSelect"
                                                                class="form-control kt-select2"
                                                            >
                                                                <!-- To display placeholder -->
                                                                <option></option>
                                                            </select>
                                                            <div
                                                                v-if="!$v.floorNumber.required"
                                                                class="invalid-feedback"
                                                            >
                                                                {{ $t("error_fieldIsRequired") }}
                                                            </div>
                                                            <span class="form-text text-muted">{{
                                                                $t("b&f_floorNumberDetailInfo")
                                                            }}</span>
                                                        </div>
                                                    </div>
                                                    <div class="form-group validated">
                                                        <label for="editFloorDescriptionTextArea">{{
                                                            $t("b&f_floorDescription")
                                                        }}</label>
                                                        <textarea
                                                            id="editFloorDescriptionTextArea"
                                                            class="form-control"
                                                            rows="3"
                                                            v-model="description"
                                                            @keyup="onChangeForm"
                                                        ></textarea>
                                                        <span class="form-text text-muted">{{
                                                            $t("b&f_floorDescriptionDetailInfo")
                                                        }}</span>
                                                    </div>
                                                    <div class="form-group validated row">
                                                        <div class="col-lg-6">
                                                            <label
                                                                for="editFloorForm_relativeAltitudeInput"
                                                            >{{ $t("b&f_floorRelativeAltitude") }}</label
                                                            >
                                                            <div class="input-group">
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text"
                                                                    ><i class="la la-upload"></i
                                                                    ></span>
                                                                </div>
                                                                <span>
                                                                    <input
                                                                        v-model="relativeAltitude"
                                                                        @change="onChangeForm"
                                                                        type="text"
                                                                        class="form-control"
                                                                        id="editFloorForm_relativeAltitudeInput"
                                                                        :placeholder="
                                                                            $t('b&f_floorRelativeAltitudeInputInfo')
                                                                        "
                                                                    />
                                                                </span>
                                                            </div>
                                                            <div
                                                                v-if="!$v.relativeAltitude.required"
                                                                class="invalid-feedback"
                                                            >
                                                                {{ $t("error_fieldIsRequired") }}
                                                            </div>
                                                            <div
                                                                v-if="!$v.relativeAltitude.decimal"
                                                                class="invalid-feedback"
                                                            >
                                                                {{ $t("error_invalidNumber") }}
                                                            </div>
                                                            <span class="form-text text-muted">{{
                                                                $t("b&f_floorRelativeAltitudeDetailInfo")
                                                            }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    class="kt-separator kt-separator--border-dashed kt-separator--space-lg kt-separator--portlet-fit"
                                                ></div>
                                                <div class="kt-section">
                                                    <h3 class="kt-section__title">
                                                        2. {{ $t("b&f_floorMap") }}
                                                    </h3>
                                                    <div class="form-group validated row">
                                                        <div class="col-lg-6">
                                                            <label>{{ $t("common_fileBrowser") }}</label>
                                                            <div class="custom-file">
                                                                <input
                                                                    type="file"
                                                                    @change="handleFileSelected"
                                                                    ref="imageFloorFile"
                                                                    :lang="this.$i18n.locale"
                                                                    class="custom-file-input cursorPointer"
                                                                    accept="image/png"
                                                                    id="selectFloorImageInputFile"
                                                                />
                                                                <label
                                                                    class="custom-file-label textLeft"
                                                                    id="selectFloorImageInputLabel"
                                                                    for="customFile"
                                                                >{{ $t("common_chooseFile") }}</label
                                                                >
                                                                <span class="form-text text-muted">{{
                                                                    $t("b&f_floorFileDetailInfo")
                                                                }}</span>
                                                            </div>
                                                            <div
                                                                v-if="!isMapUploaded"
                                                                class="invalid-feedback"
                                                            >
                                                                {{ $t("error_fieldIsRequired") }}
                                                            </div>
                                                        </div>
                                                        <div v-show="isMapUploaded" class="col-lg-6">
                                                            <div
                                                                class="imagePreview"
                                                                v-bind:style="{
                                                                    backgroundImage:
                                                                        'url(' + this.currentFloorMap + ')'
                                                                }"
                                                            ></div>
                                                            <div class="kt-margin-b-10 kt-margin-t-10">
                                                                {{ mapFileName }}
                                                            </div>
                                                            <div>
                                                                <button
                                                                    id="newFloorRemoveImageButton"
                                                                    type="button"
                                                                    @click="onRemoveImage"
                                                                    class="btn btn-outline-brand"
                                                                >
                                                                    {{ $t("common_remove") }}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div v-if="isMapUploaded" class="row">
                                                        <div class="col-lg-12">
                                                            <app-locationmap
                                                                ref="map"
                                                                mode="floorMapping"
                                                                @onchangefloormap="onChangeFloorMap"
                                                            ></app-locationmap>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="kt-portlet__foot">
                                                <div class="kt-form__actions kt-form__actions--right">
                                                    <button
                                                        id="editFloorCreateButton"
                                                        @click="onUpdateButton"
                                                        type="button"
                                                        :disabled="$v.$invalid || !isFormDataChanged"
                                                        class="btn btn-brand kt-margin-r-5"
                                                    >
                                                        <i class="la la-save"></i>
                                                        {{ $t("common_saveChanges") }}
                                                    </button>
                                                    <button
                                                        id="editFloorCancelButton"
                                                        @click="onCancelButton"
                                                        type="button"
                                                        class="btn btn-secondary"
                                                    >
                                                        {{ $t("common_cancel") }}
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                        <!--end::Form-->
                                    </div>
                                </div>
                            </div>

                            <!-- end:: Content -->
                        </div>
                        <!-- end:: Content -->
                    </div>

                    <!-- begin:: Footer -->
                    <app-footer></app-footer>
                    <!-- end:: Footer -->
                    <!-- begin:: Modal to confirm leave page -->
                    <app-confirmleavepagemodal ref="confirmleavepagemodalref"></app-confirmleavepagemodal>
                    <app-gofullscreenmodal></app-gofullscreenmodal>
                    <!-- end::Modal -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import i18n from "../../i18n";
const _floors = require("../../constants/floors");
import { required, decimal } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";
import LocationMap from "../map/locationmap.vue";
import SiteTitle from "../site/widgets/sitetitle.vue";
import commonVueHelper from "../../helpers/commonVueHelper";
import GoFullscreenModal from "../modals/gofullscreenmodal.vue";

export default {
    data() {
        return {
            name: "",
            floorNumber: "",
            description: "",
            relativeAltitude: 0,
            siteId: this.$route.params.siteId,
            buildingId: this.$route.params.buildingId,
            floorId: this.$route.params.floorId,
            mapFileName: "",
            invalidMapFormat: false,
            isMapUploaded: false,
            isFormDataChanged: false,
            imageFile: null
        };
    },
    created: function() {
        console.log("Component(editFloor)::created() - called");
        const payload = {
            siteId: this.siteId,
            buildingId: this.buildingId,
            floorId: this.floorId
        };
        this.getFloorById(payload);
    },
    mounted: function() {
        console.log("Component(editFloor)::mounted() - Init metronic layout");
        KTLayout.init();

        this.initFloorsSelect2();

        // initialize touchspin buttons on altitude input field
        $("#editFloorForm_relativeAltitudeInput")
            .TouchSpin({
                buttondown_class: "btn btn-secondary",
                buttonup_class: "btn btn-secondary",
                verticalbuttons: true,
                verticalup: '<i class="la la-angle-up"></i>',
                verticaldown: '<i class="la la-angle-down"></i>',
                min: -20,
                step: 0.01,
                decimals: 2
            })
            .on("change", () => {
                this.relativeAltitude = $("#editFloorForm_relativeAltitudeInput").val();
                this.onChangeForm();
            });
    },
    destroyed: function() {
        console.log("Component(editFloor)::destroyed() - called");
        this.unsetCurrentFloor();
        var nextRoute = this.$router.currentRoute.name;
        if (nextRoute !== "floors") {
            this.resetBuildingsState();
        }
    },
    validations: {
        // -- validations -- List of controls to apply to validate a form.
        // --
        name: {
            required
        },
        floorNumber: {
            required
        },
        relativeAltitude: {
            decimal,
            required
        },
        isMapUploaded: {
            defined: value => value === true
        }
    },
    watch: {
    // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
    // --
        currentFloor: function(floor) {
            console.log("Component(editFloor)::watch(currentFloor) - called with ", floor);
            if (floor) {
                this.name = floor.name;
                this.floorNumber = floor.floorNumber;
                this.description = floor.description;
                this.relativeAltitude = floor.relativeAltitude;
                if (floor.mapFileName) {
                    this.mapFileName = floor.mapFileName;
                }

                if ($("#editFloorFloorNumberSelect").data("select2")) {
                    $("#editFloorFloorNumberSelect").val(floor.floorNumber);
                    // Trigger change event and provide an additional param
                    $("#editFloorFloorNumberSelect").trigger({
                        type: "change",
                        params: { initialInit: true }
                    });
                }
            }
            this.isMapUploaded = true;
        },

        user: function(user) {
            console.log("Component(editFloor)::watch(user) - called with ", user);
            if (user) {
                // In case of language change
                commonVueHelper.resetImageInputLabel(this.isMapUploaded);
            }
        }
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters(["currentSite", "currentFloorMap", "currentFloor"]),

        floors: function() {
            return _.range(_floors.min, _floors.max + 1);
        }
    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        ...mapActions([
            "uploadFloorImage",
            "getFloorById",
            "updateFloor",
            "setFloorMap",
            "unsetFloorMap",
            "unsetCurrentFloor",
            "resetBuildingsState"
        ]),

        // Function called to init floors select2
        initFloorsSelect2() {
            var self = this;
            // Init unit Select2
            commonVueHelper.destroySelect2($("#newFloorFloorNumberSelect"));
            $("#editFloorFloorNumberSelect")
                .select2({
                    placeholder: i18n.t("b&f_floorNumberInputInfo"),
                    data: this.floors,
                    width: "100%",
                    minimumResultsForSearch: -1
                })
                .val(null)
                .trigger("change")
            // emit event on change.
                .on("change", function(event) {
                    self.floorNumber = Number($(this).val());
                    if (!event.params || (event.params && !event.params.initialInit)) {
                        // Trigger change form only if its a manual action from the user, not the initial init !
                        self.onChangeForm();
                    }
                });
        },

        // Function called when user click on the "Cancel" button
        onCancelButton() {
            console.log("Component(editFloor)::onCancelButton() - called");
            this.$router.push({
                name: "floors",
                params: { fromAction: "cancelButton" }
            });
        },

        onUpdateButton() {
            console.log("Component(editFloor)::onUpdateFloor() - called");

            // get mapCoordinates
            let mapCoordinates = this.$refs.map.floorImageOverlay.getGeoJsonMapCoordinates();

            // Check floor image has been modified.
            // If image has been modified, upload the new one and save changes after upload
            if (this.imageFile) {
                // New image has been selected

                const payload = {
                    nextAction: "EDIT_FLOOR",
                    floorImage: this.imageFile,
                    metadata: {
                        siteId: this.siteId,
                        buildingId: this.buildingId,
                        floorId: this.floorId,
                        name: this.name,
                        description: this.description,
                        floorNumber: this.floorNumber,
                        relativeAltitude: Number(this.relativeAltitude),
                        map: this.currentFloorMap,
                        mapFileName: this.mapFileName,
                        mapCoordinates: mapCoordinates
                    }
                };

                this.uploadFloorImage(payload);
            } else {
                const data = {
                    siteId: this.siteId,
                    buildingId: this.buildingId,
                    floorId: this.floorId,
                    name: this.name,
                    description: this.description,
                    floorNumber: this.floorNumber,
                    relativeAltitude: Number(this.relativeAltitude),
                    map: this.currentFloorMap,
                    mapFileName: this.mapFileName,
                    mapCoordinates: mapCoordinates
                };
                this.updateFloor(data);
            }
        },

        // Function called when user select an image
        handleFileSelected() {
            console.log("Component(editFloor)::handleFileSelected() - called");

            if (this.$refs.imageFloorFile.files.length > 0) {
                let imgSelectedFile = this.$refs.imageFloorFile.files[0];
                // Unset previous loaded image
                this.unsetFloorMap();
                this.mapFileName = "";
                this.isMapUploaded = false;
                // Load selected image
                if (imgSelectedFile.size > 3145728) {
                    // 3 MB max
                    alert(i18n.t("common_fileTooBig", { fileLimit: "3" }));
                    this.unsetFloorMap();
                    this.mapFileName = "";
                    this.isMapUploaded = false;
                } else {
                    this.imageFile = imgSelectedFile;
                    this.mapFileName = this.imageFile.name;
                    var reader = new FileReader();
                    reader.readAsDataURL(this.imageFile);
                    reader.onload = e => {
                        this.setFloorMap(e.target.result);
                        this.isMapUploaded = true;
                        this.$v.isMapUploaded.$touch();
                        this.onChangeForm();
                        $("#goFullscreenModal").modal("show");
                    };
                }
            } else {
                $("#selectFloorImageInputLabel").text(i18n.t("common_chooseFile"));
            }
        },

        // Function called when user click on the "Remove" button to remove image
        onRemoveImage() {
            this.mapFileName = "";
            this.isMapUploaded = false;
            this.$v.isMapUploaded.$touch();
            // Force clear of input file to be able to select the same image again
            $("#selectFloorImageInputFile").val(null);
            // Replace removed file path label by common_chooseFile text
            commonVueHelper.resetImageInputLabel();
            // Remove map and coordinates information from vuex store
            this.unsetFloorMap();
            this.onChangeForm();
        },

        onChangeForm() {
            this.isFormDataChanged = true;
        },

        onChangeFloorMap() {
            this.onChangeForm();
        }
    },
    components: {
    // -- Components -- List of local components used in the current template
    // --
        "app-locationmap": LocationMap,
        "app-sitetitle": SiteTitle,
        "app-gofullscreenmodal": GoFullscreenModal
    },

    beforeRouteLeave(to, from, next) {
    // -- Called when the route that renders this component is about to be navigated away from.
        commonVueHelper.displayConfirmModalOnLeavePage(
            this,
            "EditFloor",
            "update",
            this.isFormDataChanged,
            to,
            from,
            next
        );
    }
};
</script>

<style scoped>
.baseTemplate {
  display: contents;
}

.imagePreview {
  height: 108px;
  width: 160px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  border: 1px solid #ebedf2;
}
</style>
